<script>
export default {
  name: 'Shape',
  props: {
    path: {
      type: String,
      required: true
    },
    stroke: {
      type: String,
      required: true
    },
    strokeWidth: {
      type: Number,
      default: 1,
      required: true
    },
    isDashed: {
      type: Boolean,
      required: true
    },
    fill: {
      type: String,
      required: true,
      default: '#F6F6F6'
    }
  },
  render(createElement) {
    const { path: d, fill, stroke, strokeWidth, strokeDashArray } = this
    return createElement('path', {
      style: {
        'stroke-linecap': 'round'
      },
      attrs: {
        d,
        stroke,
        fill,
        'stroke-width': strokeWidth,
        'stroke-dasharray': strokeDashArray
      }
    })
  },
  computed: {
    strokeDashArray() {
      return this.isDashed ? 6 : 0
    }
  }
}
</script>

<style scoped></style>
