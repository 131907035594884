<script>
export default {
  name: 'Ruler',
  props: {
    x1: {
      type: Number,
      default: 0,
      required: true
    },
    x2: {
      type: Number,
      default: 0,
      required: true
    },
    y1: {
      type: Number,
      default: 0,
      required: true
    },
    y2: {
      type: Number,
      default: 0,
      required: true
    }
  },
  render(createElement) {
    const { x1, x2, y1, y2 } = this
    return createElement('line', {
      attrs: {
        x1,
        y1,
        x2,
        y2,
        stroke: '#D6D6D6',
        'stroke-width': 1,
        'stroke-dasharray': '5 5'
      }
    })
  }
}
</script>

<style scoped></style>
