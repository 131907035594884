<template>
  <g>
    <path
      :d="path"
      class="figure-path"
      fill="none"
      stroke="#999999"
      stroke-width="1"
      style="stroke-linecap: round"
    ></path>
    <text
      fill="#000"
      class="size-bias"
      text-anchor="middle"
      alignment-baseline="middle"
      dominant-baseline="middle"
      :x="text.x"
      :y="text.y"
      :transform="`rotate(${text.rotate}, ${text.x}, ${text.y})`"
    >
      {{ realValue }}
    </text>
  </g>
</template>

<script>
import { rollSizeCorrection } from '@/utils/drawing'

export default {
  props: {
    path: {
      type: String,
      default: '',
      required: true
    },
    value: {
      type: Array,
      default: null,
      required: true
    },
    scale: {
      type: Number,
      default: 0,
      required: true
    },
    text: {
      type: Object,
      default: () => ({}),
      required: true
    },
    rollWidthOptions: {
      type: Object,
      default: () => null
    },
    withCorrection: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    realValue() {
      if (this.withCorrection || this.rollWidthOptions) {
        const { minSide, delimiter, correction } = this.value[1]
        return correction ?? rollSizeCorrection(minSide, this.rollWidthOptions, delimiter)
      }

      return parseFloat((this.value[0] / this.scale).toFixed(2))
    }
  },
  name: 'ShapeSize'
}
</script>

<style scoped lang="sass">
.size
  font-size: rem(12)
  font-weight: bold
  line-height: 100%
.size-bias
  font-size: rem(12)
  fill: $tn-gray_plus
  font-weight: 500
.transparent
  .size-bias
    fill: transparent
  .figure-path
    fill: transparent
    stroke: transparent
</style>
