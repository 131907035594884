<script>
import {
  checkDistanceBetweenZeroAngleSides,
  defaultSvgParams,
  getAnglesCoordinates,
  getArrowsCoordinates,
  getDashedCoordinates,
  getDashedLineGroup,
  getFigureDrawingParams,
  getLettersCoordinates,
  getQuantityAngleZones,
  getScaledBiases,
  getShapePathByCoordinates,
  getSides,
  getSidesCoordinates,
  getSidesCoordinatesWithCorrection,
  zoneLetters
} from '@/utils/drawing'
import { figureColors } from '@/utils'
import { shapes } from '@/utils/figures'

export default {
  name: 'figureCommonSettings',
  computed: {
    colors: () => figureColors,
    defaultSvgParams: () => defaultSvgParams,
    arrowsOffset() {
      return defaultSvgParams.arrowsOffset
    },
    scaledBiases() {
      const isRectangle = this.current.form === shapes.rectangle
      const { scale } = this.figureDrawingParams
      let { biasSide, biasAngle } = getScaledBiases(getSides(this.coordinates, scale), this.buildingHeight, scale, isRectangle, this.coordinates, this.rollWidthOptions)

      if (this.rollWidthOptions) {
        biasAngle = checkDistanceBetweenZeroAngleSides(biasAngle)
      }

      return { biasSide, biasAngle }
    },
    figureDrawingParams() {
      return getFigureDrawingParams(this.current)
    },
    points() {
      return this.figureDrawingParams.points
    },
    coordinates() {
      return this.figureDrawingParams.coordinates
    },
    arrows() {
      const { biasSide, biasAngle } = this.scaledBiases
      return getArrowsCoordinates(this.coordinates, biasSide, biasAngle, this.rollWidthOptions)
    },
    sides() {
      const { biasSide, biasAngle } = this.scaledBiases
      return this.withCorrection
        ? getSidesCoordinatesWithCorrection(this.coordinates, biasSide, biasAngle)
        : getSidesCoordinates(this.coordinates, biasSide, this.rollWidthOptions)
    },
    figure() {
      const { biasSide, biasAngle } = this.scaledBiases
      const { path } = getAnglesCoordinates(this.coordinates, biasSide, biasAngle, this.rollWidthOptions, this.withCorrection)
      const dashedCoord = getDashedCoordinates(this.coordinates, biasSide, this.rollWidthOptions, this.getAngleCross(), this.getQuantityAngleZones())
      const shape = getShapePathByCoordinates(this.coordinates)
      const dashedShape = this.withCorrection
        ? getDashedLineGroup(this.coordinates, biasAngle, this.getAngleCross(), this.current.form, this.current.params)
        : getShapePathByCoordinates(dashedCoord)

      return { shape, dashedShape, angles: path }
    },
    withCorrection() {
      const { biasAngle } = this.scaledBiases
      return Object.values(biasAngle).some(item => item[1].correction)
    }
  },
  methods: {
    getQuantityAngleZones() {
      return getQuantityAngleZones(this.coordinates)
    },
    getAngleCross() {
      const { biasSide, biasAngle } = this.scaledBiases
      const { angleCoordinates } = getAnglesCoordinates(this.coordinates, biasSide, biasAngle, this.rollWidthOptions, this.withCorrection)
      const innerIntersectionIndex = 3

      return angleCoordinates.map((a) => {
        const { x, y } = a[innerIntersectionIndex]
        return { x: parseInt(x), y: parseInt(y) }
      })
    },
    getLetters(x1, x2, y1, y2) {
      const { biasSide, biasAngle } = this.scaledBiases
      const { angleCoordinates } = getAnglesCoordinates(this.coordinates, biasSide, biasAngle, this.rollWidthOptions, this.withCorrection)
      const { e } = zoneLetters
      const { lettersC, lettersD } = getLettersCoordinates(this.coordinates, biasAngle)

      const letters = [
        ...lettersC,
        ...lettersD
      ]

      const { isNeededE, xLetter, yLetter } = this.isNeededCenterLetter(
        this.current.form,
        biasAngle,
        angleCoordinates,
        this.getQuantityAngleZones(),
        { x1, x2, y1, y2 }
      )

      if (!isNeededE) {
        return letters
      }

      const x = this.rollWidthOptions ? xLetter : (x1 + x2) / 2
      const y = this.rollWidthOptions ? yLetter ?? angleCoordinates[0][1].y + 35 : (y1 + y2) / 2

      letters.push({ x, y, letter: e })

      return letters
    },
    isNeededCenterLetter(form, angles, angleCoordinates, quantityAngleZones, points) {
      const quantityAnglesWithCorrection = Object.values(angles).filter(a => a[1].correction)
      const { x1, x2, y1, y2 } = points
      const offset = 10
      let minWidthForVisibleCenterZone = 2

      switch (form) {
        case 't-shaped': {
          return {
            isNeededE: !(quantityAnglesWithCorrection?.length === 3),
            xLetter: angleCoordinates[1][1].x - ((angleCoordinates[1][1].x - angleCoordinates[0][1].x) / 2)
          }
        }
        case 'corner': {
          return {
            isNeededE: this.current.params.c >= minWidthForVisibleCenterZone || this.current.params.a >= minWidthForVisibleCenterZone,
            xLetter: angleCoordinates[0][1].x + offset,
            yLetter: angleCoordinates[0][1].y + offset
          }
        }
        case 'rectangle': {
          return {
            isNeededE: !(quantityAnglesWithCorrection?.length === 4 || quantityAnglesWithCorrection?.length === 2),
            xLetter: angleCoordinates[1][1].x - ((angleCoordinates[1][1].x - angleCoordinates[0][1].x) / 2)
          }
        }
        case 'p-shaped': {
          const cases = this.current.params.a > 2 || this.current.params.d > 1.7 || this.current.params.c > 2
          minWidthForVisibleCenterZone = cases ? 1.8 : minWidthForVisibleCenterZone
          return {
            isNeededE: this.current.params.d >= minWidthForVisibleCenterZone,
            xLetter: angleCoordinates[0][1].x + offset,
            yLetter: angleCoordinates[0][1].y + offset
          }
        }
        case 'x-shaped': {
          return {
            isNeededE: this.current.params.d >= minWidthForVisibleCenterZone,
            xLetter: (x1 + x2) / 2,
            yLetter: (y1 + y2) / 2
          }
        }
      }
    }
  }
}
</script>
