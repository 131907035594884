<script>
export default {
  name: 'ZoneLetter',
  props: {
    x: {
      type: Number,
      default: 0,
      required: true
    },
    y: {
      type: Number,
      default: 0,
      required: true
    }
  },
  render(createElement) {
    const { x, y } = this
    return createElement(
      'text',
      {
        class: 'letter',
        style: { 'dominant-baseline': 'middle' },
        attrs: {
          x,
          y,
          fill: 'black',
          'font-size': '12',
          'font-weight': 'bold',
          'text-anchor': 'middle',
          'dominant-baseline': 'middle'
        }
      },
      this.$slots.default
    )
  }
}
</script>

<style scoped lang="sass">
.size
  font-size: rem(12)
  font-weight: bold
  line-height: 100%
</style>
