<script>
export default {
  name: 'Arrow',
  props: {
    path: {
      type: String,
      default: null,
      required: true
    },
    color: {
      type: String,
      default: '#999999'
    },
    strokeWidth: {
      type: [Number, String],
      default: 2
    }
  },
  render(createElement) {
    const { path: d, color: stroke, strokeWidth } = this
    return createElement('path', {
      class: 'figure-arrow',
      style: {
        'stroke-linecap': 'round'
      },
      attrs: {
        d,
        fill: 'none',
        stroke,
        'stroke-width': strokeWidth
      }
    })
  }
}
</script>

<style scoped lang="sass">
.figure-arrow
  pointer-events: none
</style>
