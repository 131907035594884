var render, staticRenderFns
import script from "./Arrow.vue?vue&type=script&lang=js&"
export * from "./Arrow.vue?vue&type=script&lang=js&"
import style0 from "./Arrow.vue?vue&type=style&index=0&id=e5ea9836&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5ea9836",
  null
  
)

export default component.exports