<template>
  <g>
    <rect
      :x="bBox.x - 5"
      :y="bBox.y - 5"
      :height="bBox.height + 10"
      :width="bBox.width + 10"
      fill="white"
      v-if="bBox"
    ></rect>
    <text
      class="size"
      :x="x"
      :y="y"
      :fill="fill"
      ref="text"
      font-size="12"
      font-weight="bold"
      text-anchor="middle"
      style="dominant-baseline: middle"
      dominant-baseline="middle"
    >
      {{ text }}
    </text>
  </g>
</template>

<script>
export default {
  props: {
    x: {
      type: Number,
      default: 0,
      required: true
    },
    y: {
      type: Number,
      default: 0,
      required: true
    },
    fill: {
      type: String,
      default: null,
      required: true
    },
    text: {
      type: String,
      default: null,
      required: true
    }
  },
  name: 'Size',
  data: () => ({
    bBox: null
  }),
  watch: {
    sizeProperties: {
      handler: 'getBBox',
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getBBox() {
      await this.$nextTick()
      this.bBox = this.$refs.text.getBBox()
    }
  },
  mounted() {
    this.getBBox()
  },
  computed: {
    sizeProperties() {
      const { x, y, text } = this
      return { x, y, text }
    }
  }
}
</script>

<style scoped lang="sass">
.size
  font-size: rem(12)
  font-weight: bold
  line-height: 100%
</style>
